import { Link } from "react-router-dom";

const About = () => {
  return (
    <section className="section-about" id="about">
      {/* <div className="about_wrapper"> */}
      <div className="sabout">
        <Link to="/about/intro">
          <h3>ABOUT US</h3>
          <h1>关于中晟</h1>
          <p>
            中晟检验认证(重庆)有限责任公司(以下简称 ZSTC)成立于 2023 年 7
            月，统一社会信用代码:
            91500107MACQ287L1K，国家认证认可监督管理委员会批准单位，批准号:CNCA-R-2023-8888,中国认证认可协会会员单位，中国电子联合会会员单位，重庆市质量监督协会会员单位，重庆市安全生产协会会员单位。
            目前主要从事质量管理体系、环境管理体系、职业健康安全管理体系等体系认证。
          </p>
        </Link>
      </div>
      <div className="join">
        <Link to="/join">
          <h3>JOIN US</h3>
          <h1>加入我们</h1>
          <p>欢迎在中晟开启你的职业生涯, 和我们一起成长</p>
        </Link>
      </div>
    </section>
    // </div>
  );
};

export default About;
