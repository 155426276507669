import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { HelmetProvider } from "react-helmet-async";
import Home from "./pages/Home";
import About from "./pages/About";
import Service from "./pages/Service";
import Solution from "./pages/Solution";
import NotFound from "./pages/NotFound";
import CertQuery from "./pages/CertQuery";
import Contact from "./pages/Contact";
import Nav from "./components/Nav";
import TopBar from "./components/TopBar";
import SideBar from "./components/SideBar";
import Intro from "./components/About/Intro";
import Academic from "./components/About/Academic";
import Claim from "./components/About/Claim";
import Qms from "./components/Service/Qms";
import Ems from "./components/Service/Ems";
import Ohsms from "./components/Service/Ohsms";
import IT from "./components/Solution/IT";
import Funiture from "./components/Solution/Furniture";
import Food from "./components/Solution/Food";
import Construction from "./components/Solution/Construction";
import Guard from "./components/Solution/Guard";
import Ecnomics from "./components/Solution/Ecnomics";
import Logistics from "./components/Solution/Logistics";
import NewEnergy from "./components/Solution/NewEnergy";
import Local from "./components/CertQuery/Local";
import Inquiry from "./components/Contact/Inquiry";
import Complain from "./components/Contact/Complain";

function App() {
  return (
    <Router>
      <TopBar />
      <SideBar />
      <Nav />
      <HelmetProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />}>
            <Route index element={<Navigate to="intro" />} />
            <Route path="intro" index element={<Intro />} />
            <Route path="claim" element={<Claim />} />
            <Route path="academic" element={<Academic />} />
          </Route>
          <Route path="/services" element={<Service />}>
            <Route index element={<Navigate to="qms" />} />
            <Route path="qms" index element={<Qms />} />
            <Route path="ems" element={<Ems />} />
            <Route path="ohsms" element={<Ohsms />} />
          </Route>
          <Route path="/solution" element={<Solution />}>
            <Route index element={<Navigate to="IT" />} />
            <Route path="IT" index element={<IT />} />
            <Route path="furniture" element={<Funiture />} />
            <Route path="food" element={<Food />} />
            <Route path="construction" element={<Construction />} />
            <Route path="guard" index element={<Guard />} />
            <Route path="logistics" element={<Logistics />} />
            <Route path="ecnomics" element={<Ecnomics />} />
            <Route path="newenergy" element={<NewEnergy />} />
          </Route>
          <Route path="/cert-query" element={<CertQuery />}>
            <Route index element={<Navigate to="local" />} />
            <Route path="local" element={<Local />} />
          </Route>
          <Route path="/contact" element={<Contact />}>
            <Route index element={<Navigate to="inquiry" />} />
            <Route path="inquiry" element={<Inquiry />} />
            <Route path="complain" element={<Complain />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </HelmetProvider>
    </Router>
  );
}

export default App;
