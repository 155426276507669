import { Helmet } from "react-helmet-async";
import PageContainer from "../components/page-parts/pageContainer";

const About = () => {
  const data = {
    class: "about",
    heading: "关于我们",
    image: "/assets/images/about-us.jpg",
    nav: [
      { title: "机构简介", path: "intro" },
      { title: "学术园地", path: "academic" },
      { title: "公正性声明", path: "claim" },
    ],
  };

  return (
    <>
      <Helmet>
        <title>关于中晟</title>
        <meta name="description" content="about us" />
      </Helmet>
      <PageContainer data={data} />
    </>
  );
};

export default About;
