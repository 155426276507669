import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

const Industry = () => {
  const industryImages = [
    "industry-3d-print.jpg",
    "industry-chemical.jpg",
    "industry-AI.jpg",
    "industry-IT.jpg",
    "industry-env.jpg",
    "industry-furniture.jpg",
    "industry-robot.jpg",
    "industry-food.jpg",
    "industry-construction.jpg",
    "industry-energy.jpg",
  ];
  const dir = "/assets/images/";
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalID = setInterval(() => {
      console.log("industry carousel");
      setCurrentIndex((preIndex) => (preIndex + 1) % industryImages.length);
    }, 3000);

    return () => clearInterval(intervalID);
  }, [industryImages.length]);

  return (
    <section className="section-industry flex ">
      <div
        className="left"
        style={{
          backgroundImage: ` url(${dir + industryImages[currentIndex]})`,
        }}
      >
        <h3>INDUSTRY SOLUTION</h3>
        <h1>行业解决方案</h1>
        <p>
          ZSTC |
          中晟根据客户的需求定制专属的认证解决方案，满足不同客户的不同需求。我们将根据客户的实际情况，提供最适合的认证方案。
          中晟持续关注认证行业的发展趋势，不断优化我们的服务，为客户提供更好的认证解决方案。
        </p>
      </div>
      <div className="right">
        <div className="box">
          <Link to="/solution/IT">
            <div className="info-cell">
              <p className="title">IT / 信息技术行业认证</p>
              <ul className="info">
                <li>ISO 27001: 信息安全管理体系</li>
                <li>ISO 20000: 信息技术服务管理体系</li>
                <li>ISO 22301: 业务连续性管理体系</li>
                <li>GB/T 39604: 社会责任管理体系</li>
                <li>ISO 9001: 质量管理体系认证</li>
                <li>ISO 14001: 环境管理体系</li>
                <li>ISO 45001: 职业健康安全管理体系</li>
              </ul>
            </div>
            <p className="title-cell">IT / 信息技术行业认证</p>
          </Link>
        </div>
        <div className="box">
          <Link to="/solution/construction">
            <div className="info-cell">
              <p className="title">工程建设施工行业认证</p>
              <ul className="info">
                <li>GB/T 50430: 建筑工程行业质量管理体系</li>
                <li>CTS CICC-S01: 建筑工程绿色施工认证</li>
                <li>ISO 9001: 质量管理体系认证</li>
                <li>ISO 14001: 环境管理体系</li>
                <li>ISO 45001: 职业健康安全管理体系</li>
                <li>ISO 39001: 道路交通安全管理体系</li>
              </ul>
            </div>
            <p className="title-cell">工程建设施工行业认证</p>
          </Link>
        </div>
        <div className="box">
          <Link to="/solution/food">
            <p className="title-cell">食品行业认证</p>
            <div className="info-cell">
              <p className="title">食品行业认证</p>
              <ul className="info">
                <li>ISO 22000: 食品安全管理体系</li>
                <li>HACCP: 危害分析与关键控制点</li>
                <li>GB/T 19630: 有机产品OP</li>
                <li>GB/T 20014.1: 良好农业规范GAP</li>
                <li>RB/T 309: 餐厅餐饮服务</li>
                <li>RB/T 304: 城市配送服务</li>
                <li>GB/T 33300: 食品工业企业诚信管理体系</li>
                <li>GB/T 23793: 合格供应商信用评价</li>
                <li>ISO 28000: 供应链安全管理体系认证</li>
              </ul>
            </div>
            <p className="title-cell">食品行业认证</p>
          </Link>
        </div>
        <div className="box">
          <Link to="/solution/furniture">
            <div className="info-cell">
              <p className="title">家具行业认证</p>
              <ul className="info">
                <li>CTS CICC-S02: 家具定制服务</li>
                <li>CTS CICC-S03: 品质验证</li>
                <li>CTS CICC-S08: 绿色供应链管理体系</li>
                <li>GB/T 39604: 社会责任管理体系</li>
                <li>GB/T31950: 企业诚信管理体系</li>
                <li>ISO 55001: 资产管理体系</li>
                <li>ISO 22301: 业务连续性管理体系</li>
                <li>GB/T 15496: 企业标准体系</li>
                <li>CTS GJC380-01: 家具有害物质限量认证</li>
              </ul>
            </div>
            <p className="title-cell">家具行业认证</p>
          </Link>
        </div>

        <Link to="/solution/IT" className="btn-text">
          查看更多 &rarr;
        </Link>
      </div>
    </section>
  );
};

export default Industry;
