import { Helmet } from "react-helmet-async";
import PageContainer from "../components/page-parts/pageContainer";

const Service = () => {
  const data = {
    class: "services",
    heading: "我们的服务",
    image:
      "/assets/images/service-quote-text-written-on-wooden-block-business-motivation-inspiration.jpg",
    nav: [
      { title: "质量管理体系认证", path: "qms" },
      { title: "环境管理体系认证", path: "ems" },
      { title: "职业健康安全管理体系认证", path: "ohsms" },
    ],
  };

  return (
    <>
      <Helmet>
        <title>我们的服务</title>
        <meta name="description" content="our services" />
      </Helmet>
      <PageContainer data={data} />
    </>
  );
};

export default Service;
