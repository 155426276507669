const Intro = () => {
  return (
    <div className="intro">
      <h1>机构简介</h1>
      <p>
        <b>中晟检测认证(重庆) 有限责任公司</b>成立于 2023 年 7 月,
        一家专注于提供三体系认证服务的领先机构。多年来，我们致力于为各类组织提供专业的认证服务，帮助客户提升管理水平，增强市场竞争力。
      </p>
      <p>
        <b>我们的核心价值观：</b>
        秉承专业、诚信、高效的服务理念，致力于为客户提供最优质的认证服务。我们深知质量、环境和职业健康安全对于企业的重要性，因此，我们的团队始终以客户需求为导向，为客户量身定制最适合的认证方案。
      </p>
      <p>主要业务范围：</p>
      <ul>
        <li>
          <b>质量管理体系认证（ISO 9001）：</b>
          我们通过对组织质量管理体系的审核，帮助客户确保其符合ISO
          9001国际标准要求，从而提升产品和服务质量，增强客户信心。
        </li>
        <li>
          <b>环境管理体系认证（ISO 14001）：</b>
          我们通过对组织环境管理体系的审核，帮助客户确保其符合ISO
          14001国际标准要求，推动环保理念，实现可持续发展。
        </li>
        <li>
          <b>职业健康安全管理体系认证（ISO 45001）：</b>
          我们通过对组织职业健康安全管理体系的审核，帮助客户确保其符合ISO
          45001国际标准要求，保障员工健康安全，提升企业形象。
        </li>
      </ul>
      <p>
        未来发展规划：
        我们将继续致力于三体系认证业务的提升和发展，不断完善服务体系，提高服务质量。同时，我们也将积极拓展其他认证领域，为客户提供更加全面的认证服务，助力客户实现可持续发展。
      </p>
      <p>我们期待与您的合作！</p>
    </div>
  );
};

export default Intro;
