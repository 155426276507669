import { useState, useEffect } from "react";

const Banner = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const images = [
    {
      url: "/assets/images/banner-sailing-tallship-gm176827711-1331024.jpg",
      tilte: "专注认证事业",
      describe: "我们正启航",
    },
    {
      url: "/assets/images/banner-sailing-tallship-gm176827711-1331024.jpg",
      tilte: "全行业解决方案",
      describe: "为全行业提供一站式解决方案",
    },
    {
      url: "/assets/images/banner-two-old-sailing-ships-at-sea-gm619668900-108122727.jpg",
      tilte: "中晟认证, 值得信赖!",
      describe:
        "我们致力于成为社会放心, 企业信任的高技术认证服务机构和国际上有影响力的认证机构",
    },
  ]; // TODO: 应该在 data 里定义

  useEffect(() => {
    let intervalId;

    if (!isHovered) {
      intervalId = setInterval(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
      }, 3000);
    }

    return () => clearInterval(intervalId);
  }, [isHovered]);

  useEffect(() => {
    const handleResize = () => {
      console.log("handle resize");
      setWindowWidth(window.innerWidth);
    };

    let timeout;
    window.addEventListener("resize", function () {
      timeout && clearTimeout(timeout);

      timeout = setTimeout(handleResize, 200);
    });

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div
      className="banner"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ul
        style={{
          width: windowWidth * images.length,
          left: -currentIndex * windowWidth,
        }}
      >
        {images.map((img, index) => (
          <li
            key={index}
            style={{
              width: windowWidth,
              height: (windowWidth * 1) / 3,
            }}
          >
            {/* <img src={img.url} alt={`banner${index}`} /> */}
            <div className="text">
              <h1>{img.tilte}</h1>
              <p>{img.describe}</p>
            </div>
          </li>
        ))}
      </ul>
      <div className="dots">
        {images.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentIndex ? "active" : ""}`}
            onClick={() => handleDotClick(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Banner;
