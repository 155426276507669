import { useState } from "react";

const Local = () => {
  const [companyName, setCompanyName] = useState("");
  const [certificateNumber, setCertificateNumber] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [result, setResult] = useState("");

  const handleSearch = async () => {
    try {
      // 发送查询请求到后端接口
      const response = await fetch("https://your-api-url/search", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ companyName, certificateNumber, captcha }),
      });
      const data = await response.json();
      setResult(data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="input-field">
        <input
          type="text"
          placeholder="请输入企业完整名称"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
        />
      </div>
      <div className="input-field">
        <input
          type="text"
          placeholder="请输入完整的证书编号"
          value={certificateNumber}
          onChange={(e) => setCertificateNumber(e.target.value)}
        />
      </div>
      {/* <div className="input-field">
        <input
          type="text"
          placeholder="验证码"
          value={captcha}s
          onChange={(e) => setCaptcha(e.target.value)}
        />
      </div> */}
      <button className="button" onClick={handleSearch}>
        查询
      </button>
      {result && (
        <div className="result">
          <p>企业名称: {result.companyName}</p>
          <p>证书编号: {result.certificateNumber}</p>
          <p>证书信息: {result.certificateInfo}</p>
        </div>
      )}
    </>
  );
};

export default Local;
