import { Helmet } from "react-helmet-async";
import PageContainer from "../components/page-parts/pageContainer";

const Contact = () => {
  const data = {
    class: "contact",
    heading: "联系我们",
    image: "/assets/images/contact-us.jpg",
    nav: [
      { title: "咨询留言", path: "inquiry" },
      { title: "投诉建议", path: "complain" },
    ],
  };

  return (
    <>
      <Helmet>
        <title>证书查询</title>
        <meta name="description" content="contact us" />
      </Helmet>
      <PageContainer data={data} />
    </>
  );
};

export default Contact;
