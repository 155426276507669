const Ems = () => {
  return (
    <div className="ems">
      <h1>ISO 14001 环境管理体系认证（EMS）</h1>
      <p>
        环境管理体系认证是指组织通过实施符合国际环境管理体系标准要求的环境管理体系，并由独立的认证机构对其进行审核认证的过程。目前，国际上最常用的环境管理体系标准是
        ISO 14001。ISO
        14001是国际标准化组织（ISO）制定的一套环境管理体系标准，旨在帮助组织管理、控制和改善其对环境的影响，以确保其活动符合环境法规要求，提高环境绩效，实现可持续发展。
      </p>
      <p>环境管理体系认证通常包括以下几个阶段： </p>
      <ul>
        <li>
          <b>准备阶段：</b> 组织决定实施环境管理体系，并开始准备相关文件和程序。
        </li>
        <li>
          <b>培训阶段：</b>
          组织培训员工，使其了解和掌握环境管理体系的要求。
        </li>
        <li>
          <b>实施阶段：</b>
          组织开始正式实施环境管理体系，并逐步完善和优化体系。
        </li>
        <li>
          <b>内审阶段：</b>
          组织进行内部审核，评估环境管理体系的运行情况，并提出改进建议。
        </li>
        <li>
          <b>认证审核阶段：</b>
          组织邀请独立的认证机构进行认证审核，以评估环境管理体系是否符合ISO
          14001的要求。
        </li>
        <li>
          <b>获证阶段： </b>组织通过认证审核，获得ISO
          14001环境管理体系认证证书。
        </li>
        <li>
          <b>持续改进阶段：</b>
          组织持续改进环境管理体系，确保其持续有效运行，并定期接受认证机构的监督审核。
        </li>
      </ul>
      <p>
        环境管理体系认证能够帮助组织合理利用资源，减少对环境的污染和破坏，提高环境绩效，降低环境风险，提升社会形象和竞争力，符合可持续发展的理念。
      </p>
    </div>
  );
};

export default Ems;
