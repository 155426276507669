import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Nav = () => {
  const menuData = [
    { text: { en: "home", cn: "首页" }, link: "/" },
    {
      text: { en: "about", cn: "关于我们" },
      link: "/about/intro",
      submenu: [
        { text: { en: "intro", cn: "机构简介" }, link: "/about/intro" },
        { text: { en: "academic", cn: "学术园地" }, link: "/about/academic" },
        { text: { en: "claim", cn: "公正性声明" }, link: "/about/claim" },
      ],
    },
    {
      text: { en: "services", cn: "认证服务" },
      link: "/services/qms",
      submenu: [
        { text: { en: "qms", cn: "质量管理体系" }, link: "/services/qms" },
        { text: { en: "ems", cn: "环境管理体系" }, link: "/services/ems" },
        {
          text: { en: "ohsms", cn: "职业健康安全管理体系" },
          link: "/services/ohsms",
        },
      ],
    },
    {
      text: { en: "solution", cn: "行业服务" },
      link: "/solution/IT",
      submenu: [
        {
          text: { en: "IT", cn: "IT 信息技术" },
          link: "/solution/IT",
        },
        {
          text: { en: "funiture", cn: "家具" },
          link: "/solution/funiture",
        },
        {
          text: { en: "construction", cn: "建筑工程施工" },
          link: "/solution/funiture",
        },
        {
          text: { en: "food", cn: "食品" },
          link: "/solution/food",
        },
        {
          text: { en: "guard", cn: "保安" },
          link: "/solution/guard",
        },
        {
          text: { en: "logistics", cn: "交通运输物流" },
          link: "/solution/logistics",
        },
      ],
    },
    {
      text: { en: "cert-query", cn: "证书查询" },
      link: "/cert-query/local",
      submenu: [
        { text: { en: "local", cn: "本站查询" }, link: "/cert-query/local" },
        {
          text: { en: "official", cn: "官方查询" },
          link: "http://cx.cnca.cn/CertECloud/index/index/page",
        },
      ],
    },
    {
      text: { en: "contact", cn: "联系我们" },
      link: "/contact/inquiry",
      submenu: [
        {
          text: { en: "inquiry", cn: "咨询留言" },
          link: "/contact/inquiry",
        },
        {
          text: { en: "complain", cn: "投诉建议" },
          link: "/contact/complain",
        },
      ],
    },
    { text: { en: "downloads", cn: "资料下载" }, link: "/downloads" },
  ];

  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [activeMenu, setActiveMenu] = useState("home");
  const [clicked, setClicked] = useState(false);

  const handleClick = (menu) => {
    setActiveMenu(menu);
    setOpenSubmenu(null);
    setClicked(!clicked);
  };

  const handleCheckboxChange = () => {
    setClicked(!clicked);
  };

  // 处理鼠标悬停事件
  const handleSubmenuToggle = (index) => {
    setOpenSubmenu(openSubmenu === index ? null : index);
  };

  // 处理鼠标悬停事件
  const handleMouseEnter = (index) => {
    setOpenSubmenu(index);
  };

  // 处理鼠标移出事件
  const handleMouseLeave = () => {
    setOpenSubmenu(null);
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    console.log("Current URL:", currentUrl);
    const parsedURL = new URL(currentUrl);

    const currentVisitedMenu = parsedURL.pathname.split("/")[1];
    currentVisitedMenu && setActiveMenu(currentVisitedMenu);
  }, []);

  return (
    <div className="navbar">
      <div className="navigation">
        <Link className="logo" to="/">
          ZSTC
        </Link>
        <input
          type="checkbox"
          className="navigation__checkbox"
          id="navi-toggle"
          // defaultChecked={clicked}
          checked={clicked}
          onChange={handleCheckboxChange}
        />

        <label htmlFor="navi-toggle" className="navigation__button">
          <span className="navigation__icon">&nbsp;</span>
        </label>

        <nav className="nav">
          <ul className="nav-list">
            {menuData.map((item, index) => (
              <li
                key={index}
                className="nav-item"
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <Link
                  to={item.link}
                  className={activeMenu === item.text.en ? "active" : ""}
                  onClick={(e) => {
                    // e.preventDefault(); // 阻止默认链接行为
                    handleClick(item.text.en);
                  }}
                >
                  {item.text.cn}
                </Link>
                {item.submenu && (
                  <span
                    className="submenu-button"
                    onClick={() => handleSubmenuToggle(index)}
                  >
                    {openSubmenu === index ? "-" : "+"}
                  </span>
                )}
                {item.submenu && (
                  <ul
                    className={`submenu ${openSubmenu === index ? "open" : ""}`}
                  >
                    {item.submenu.map((subitem, subindex) => (
                      <li key={subindex} className="submenu-item">
                        <Link
                          to={subitem.link}
                          onClick={(e) => {
                            // e.preventDefault(); // 阻止默认链接行为
                            handleClick(item.text.en);
                          }}
                        >
                          {subitem.text.cn}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <div className="lls">
        <div className="login">
          <Link to="/login">登录 / 注冊</Link>
        </div>
        <div className="lang">
          <span>语言</span>
          <ul>
            <li className="on">
              <Link to="/zh-cn/" title="中文">
                中文
              </Link>
            </li>
            <li className="">
              <Link to="/en-us/" title="英文">
                英文
              </Link>
            </li>
            <li className="">
              <Link to="/ja-jp/" title="日文">
                日文
              </Link>
            </li>
          </ul>
        </div>
        <div className="search">
          <button>搜索</button>
          <div className="form-wrap">
            <div className="form-inner">
              <input
                className="keyword"
                type="search"
                placeholder="请输入关键字"
              />
              <button type="submit">搜索</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
