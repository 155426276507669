const Claim = () => {
  return (
    <div className="about claim">
      <h1 className="heading">公正性声明</h1>
      <p>
        我们是一家专业从事管理体系、产品和服务认证的第三方机构，我们致力于为客户提供公正、专业、高效的认证服务，为社会和市场提供可信赖的认证证书。我们充分理解公正性在认证活动中的重要性，我们遵循以下原则和措施，确保我们的认证活动的公正性：
      </p>
      <ul>
        <li>
          独立性与客观性：我们独立于任何利益关系，致力于客观、公正地评估每个案例。
        </li>
        <li>
          专业素养：我们的团队由经验丰富、具备专业素养的专家组成，他们将根据权威标准和流程，对每个案例进行严格的评估和认证。
        </li>
        <li>
          保密性：我们严格遵守保密协议，对客户提供的信息和数据进行严格保密，绝不泄露任何个人或机构的信息。
        </li>
        <li>
          公平竞争：我们致力于维护市场公平竞争的环境，不偏袒任何一方，严格按照标准程序对每个案例进行处理。
        </li>
        <li>
          诚信与透明度：我们秉承诚信和透明的原则，确保客户了解我们的评估和认证过程，并提供及时、准确的信息。
        </li>
        <li>
          持续改进：我们不断改进我们的服务和流程，以确保我们始终保持在行业的最高标准下。
        </li>
      </ul>

      <p>
        我们遵守国家和国际有关认证的法律法规、标准规范和认可准则，按照国际通行的认证原则和规则，开展符合国家强制性或自愿性要求的认证业务
      </p>
      <p>
        我们建立了完善的内部治理结构和管理体系，设立了公正性管理委员会，由与认证活动有关的各方利益代表组成，负责对我们的认证活动进行监督、审查和评价，确保我们的认证决策和过程是独立、客观、透明和非歧视的。
      </p>
      <p>
        我们对所有申请人和获证客户一视同仁，不附加任何不合理和不正当的财务或其他条件，不以任何带有歧视性的形式处理受理认证要求，为广大客户提供同等的优质服务。
        我们不进行任何对认证客户的咨询活动，不与任何认证客户进行认证范围内的商业往来，不向任何认证客户提供内部审核，不开展任何认可范围之外的认证业务，不允许商业、财务或其他压力损害公正性
        。
      </p>
      <p>
        我们对参与认证活动的所有人员进行严格的选拔、培训、考核和监督，确保他们具有相应的资质、能力和经验，遵守相关的职业道德和行为规范，对可能引发利益冲突或影响公正性的情况进行识别、申报和回避
        。
      </p>
      <p>
        我们在全球范围内获得了多个国家和地区以及行业组织的认可，这些广泛的认可意味着我们通过了相关机构基于国际认可规则和本地法规的严格评审，为获证客户提供了进一步的保证，即我们的证书是可信任的和公正的。
      </p>
      <p>
        我们建立了完善的受理和处理来自申请人或其他方面有关认证业务或其他相关事项的申诉、投诉和争议的程序，接受和配合内部和外部各相关方的管理监督。我们对所有申诉、投诉和争议进行公正、及时、有效地处理，并保持相关记录。
      </p>
      <p>
        我们高度尊重来自客户或其他方面的信息的保密性，并建立和实施了符合法规要求和国际标准的信息安全与隐私保护内控机制。我们不会在未经授权或法律要求的情况下，向任何第三方披露任何与认证活动相关的机密信息。
      </p>
      <p>
        我们将持续改进我们的公正性管理体系，以满足客户、社会和市场的期望和需求。我们欢迎各方对我们的认证活动提出宝贵的意见和建议，以帮助我们提高我们的服务质量和水平。
      </p>
    </div>
  );
};

export default Claim;
