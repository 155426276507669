import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <section className="section-footer footer">
      <div class="placeholder"></div>
      <div className="footer__content">
        <div className="left">
          <h1>ZSTC | 中晟</h1>
          <ul>
            <li>电话: 136****6666 </li>
            <li>邮件: sales@zs.com </li>
          </ul>
        </div>
        <div className="right">
          <dl>
            <dt>
              <Link to="/service/system" className="zh16">
                我们的服务
              </Link>
            </dt>
            <dd>
              <Link to="/service/system">体系认证</Link>
            </dd>

            <dd>
              <Link to="/service/service">服务认证</Link>
            </dd>

            <dd>
              <Link to="/service/product">产品认证</Link>
            </dd>

            <dd>
              <Link to="/service/testing">检测服务</Link>
            </dd>
          </dl>
          <dl>
            <dt>
              <Link to="/solution/IT" className="zh16">
                行业解决方案
              </Link>
            </dt>
            <dd>
              <Link to="/solution/IT">IT 信息技术行业</Link>
            </dd>

            <dd>
              <Link to="/solution/furniture">家具行业</Link>
            </dd>

            <dd>
              <Link to="/solution/construction">建筑工程施工行业</Link>
            </dd>

            <dd>
              <Link to="/solution/newenergy">新能源</Link>
            </dd>

            <dd>
              <Link to="/solution/guard">保安行业</Link>
            </dd>

            <dd>
              <Link to="/solution/ecnomics">金融行业</Link>
            </dd>

            <dd>
              <Link to="/solution/logistics">交通运输物流行业</Link>
            </dd>
          </dl>
          <dl>
            <dt>
              <Link to="/about/intro" className="zh16">
                关于我们
              </Link>
            </dt>
            <dd>
              <Link to="/about/intro">机构简介</Link>
            </dd>

            <dd>
              <Link to="/about/academics">学术园地</Link>
            </dd>

            <dd>
              <Link to="/about/claim">公正性声明</Link>
            </dd>
          </dl>
          <dl>
            <dt>
              <Link to="/cert-query/local">证书查询</Link>
            </dt>
            <dd>
              <Link to="/cert-query/local">本站查询</Link>
            </dd>

            <dd>
              <Link to="/dowload">资料下载</Link>
            </dd>

            <dd>
              <Link to="http://cx.cnca.cn/CertECloud/index/index/page">
                官方查询
              </Link>
            </dd>
          </dl>
          <dl>
            <dt>
              <Link to="/contact/inquiry" className="zh16">
                联系我们
              </Link>
            </dt>

            <dd>
              <Link to="/contact/inquiry">咨询留言</Link>
            </dd>

            <dd>
              <Link to="/contact/complain">投诉建议</Link>
            </dd>
          </dl>
        </div>
      </div>
      <div className="copyright">
        © Copyright ZSTC | 中晟检测认证(重庆)有限责任公司版权所有
        渝ICP备2024031397号 | 渝公网安备 xxxxxxxx 号
      </div>
    </section>
  );
};

export default Footer;
