const Complain = () => {
  return (
    <>
      <h1>感谢您对中晟的关注</h1>
      <div>
        <input type="text" placeholder="请填写称呼" />
        <input type="text" placeholder="请填写手机号码" />
      </div>
      <textarea
        name="msg"
        id="msg"
        placeholder="请填写您的投诉或建议, 我们会尽快与您取得联系"
        spellcheck="false"
      ></textarea>

      <div>
        <button>提交</button>
      </div>
    </>
  );
};

export default Complain;
