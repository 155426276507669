import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

import Banner from "../components/Banner";
// import Service from "../components/Service";
import Footer from "../components/Footer";
import Industry from "../components/Industry";
import Case from "../components/Case";
import About from "../components/About";
import SVC from "../components/SVC";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>中晟检测认证 - ZSTC</title>
        <meta
          name="description"
          content="ZhongSheng Testing&Certification CO. ltd"
        />
        <meta name="keywords" content="认证, 检测" />
      </Helmet>
      <div className="home">
        <Banner />
        <SVC />
        {/* <Service /> */}

        <Industry />
        <Case />
        <About />
      </div>
      <Footer />
    </>
  );
};
export default Home;
