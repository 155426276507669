import { Helmet } from "react-helmet-async";
import PageContainer from "../components/page-parts/pageContainer";

const CertQuery = () => {
  const data = {
    class: "cert-query",
    heading: "证书查询",
    image: "/assets/images/cert-query.jpeg",
    nav: [
      { title: "本地查询", path: "local" },
      {
        title: "官方查询",
        path: "http://cx.cnca.cn/CertECloud/index/index/page",
      },
    ],
  };

  return (
    <>
      <Helmet>
        <title>证书查询</title>
        <meta name="description" content="certificate query" />
      </Helmet>
      <PageContainer data={data} />
    </>
  );
};

export default CertQuery;
