import { Outlet } from "react-router-dom";

import Header from "../Header";
import SideNav from "./sideNav";
import Footer from "../Footer";

const PageContainer = ({ data, children }) => {
  return (
    <>
      <Header heading={data.heading} image={data.image} />
      <div className="container">
        <div className="left">
          <SideNav navData={data.nav} />
        </div>
        <div className="right">
          <div className={data.class}>
            <Outlet />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PageContainer;
