import { Helmet } from "react-helmet-async";
import PageContainer from "../components/page-parts/pageContainer";
import { useEffect } from "react";

const Solution = () => {
  const data = {
    class: "industry",
    heading: "行业解决方案",
    image:
      "/assets/images/industry-background-various-industry-concept-business-network-sustainable-1-5.jpg",
    nav: [
      { title: "IT 信息技术行业", path: "IT" },
      { title: "工程建设施工行业", path: "construction" },
      { title: "食品行业", path: "food" },
      { title: "家具行业", path: "funiture" },
      { title: "保安行业", path: "guard" },
      { title: "交通运输物流行业", path: "logistics" },
      { title: "金融行业", path: "ecnomics" },
      { title: "新能源行业", path: "newenergy" },
    ],
  };

  useEffect(() => window.scrollTo(0, 0));

  return (
    <>
      <Helmet>
        <title>行业解决方案</title>
        <meta name="description" content="industry resolution" />
      </Helmet>
      <PageContainer data={data} />
    </>
  );
};

export default Solution;
