const Qms = () => {
  return (
    <div className="qms">
      <h1>ISO 9001 质量管理体系认证（QMS）</h1>
      <p>
        质量管理体系认证是指组织通过实施符合国际标准要求的质量管理体系，并由独立的认证机构对其进行审核认证的过程。目前，国际上最常用的质量管理体系标准是
        ISO
        9001。ISO9001是国际标准化组织（ISO）制定的一套质量管理体系标准，旨在帮助组织确立和实施一套能够持续改进的质量管理体系，以提高产品和服务质量，增强客户满意度，提升组织绩效。
      </p>
      <p>质量管理体系认证通常包括以下几个阶段： </p>
      <ul>
        <li>
          <b>准备阶段：</b> 组织决定实施质量管理体系，并开始准备相关文件和程序。
        </li>
        <li>
          <b>培训阶段：</b>
          组织培训员工，使其了解和掌握质量管理体系的要求。
        </li>
        <li>
          <b>实施阶段：</b>
          组织开始正式实施质量管理体系，并逐步完善和优化体系。
        </li>
        <li>
          <b>内审阶段：</b>
          组织进行内部审核，评估质量管理体系的运行情况，并提出改进建议。
        </li>
        <li>
          <b>认证审核阶段：</b>
          组织邀请独立的认证机构进行认证审核，以评估质量管理体系是否符合ISO
          9001的要求。
        </li>
        <li>
          <b>获证阶段： </b>组织通过认证审核，获得ISO 9001质量管理体系认证证书。
        </li>
        <li>
          <b>持续改进阶段：</b>
          组织持续改进质量管理体系，确保其持续有效运行，并定期接受认证机构的监督审核。
        </li>
      </ul>
      <p>
        质量管理体系认证能够帮助组织建立起一套科学的管理体系，提高组织的管理水平和运营效率，增强组织的竞争力，提升产品和服务的质量，提高客户满意度，为组织的可持续发展奠定基础。
      </p>
    </div>
  );
};

export default Qms;
