import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

const NotFound = () => {
  const [state] = useState({
    heading: "Oops, 404",
    paragraph: "Sorry, the page you are looking for does not exist.",
    image: "/assets/images/404.jpg",
  });
  return (
    <>
      <Helmet>
        <title>Not found</title>
        <meta name="description" content="page not found" />
      </Helmet>
      <div className="notfound">
        <img src="/assets/images/not-found-background.jpg" alt="NOT FOUND" />
        <div className="contents">
          <h1>Oops !!!</h1>
          <p>页面未找到 ~</p>
          <Link className="btn btn--white" to="/">
            返回首页
          </Link>
        </div>
      </div>
    </>
  );
};

export default NotFound;
