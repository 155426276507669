import { Link } from "react-router-dom";

const SideNav = ({ navData }) => {
  const handleClick = () => {};

  return (
    <div className="sidenav">
      <nav>
        <ul>
          {navData.map((data, index) => (
            <li key={index}>
              <Link to={data.path} onClick={() => handleClick()}>
                {data.title}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default SideNav;
