import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const SideBar = () => {
  const [showBackToTop, setShowBackToTop] = useState(false);

  useEffect(() => {
    let timeout;

    const handleScroll = () => {
      timeout && clearTimeout(timeout);

      timeout = setTimeout(() => {
        if (window.scrollY > 0) {
          console.log("window.scrollY", window.scrollY);
          setShowBackToTop(true);
        } else {
          setShowBackToTop(false);
        }
      }, 200);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="sidebar">
      <ul>
        <li className="pc-version">
          <Link to="javascript:void(0);" target="_blank">
            <div className="phonenumber">400 888 8888</div>
            <div className="content">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path
                  fill="#fff"
                  d="M280 0C408.1 0 512 103.9 512 232c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-101.6-82.4-184-184-184c-13.3 0-24-10.7-24-24s10.7-24 24-24zm8 192a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm-32-72c0-13.3 10.7-24 24-24c75.1 0 136 60.9 136 136c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-48.6-39.4-88-88-88c-13.3 0-24-10.7-24-24zM117.5 1.4c19.4-5.3 39.7 4.6 47.4 23.2l40 96c6.8 16.3 2.1 35.2-11.6 46.3L144 207.3c33.3 70.4 90.3 127.4 160.7 160.7L345 318.7c11.2-13.7 30-18.4 46.3-11.6l96 40c18.6 7.7 28.5 28 23.2 47.4l-24 88C481.8 499.9 466 512 448 512C200.6 512 0 311.4 0 64C0 46 12.1 30.2 29.5 25.4l88-24z"
                ></path>
              </svg>
              <div>电话联系</div>
            </div>
          </Link>
        </li>
        <li className="mobile-version">
          <Link to="tel:400 888 8888" target="_blank">
            <div className="content">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path
                  fill="#fff"
                  d="M280 0C408.1 0 512 103.9 512 232c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-101.6-82.4-184-184-184c-13.3 0-24-10.7-24-24s10.7-24 24-24zm8 192a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm-32-72c0-13.3 10.7-24 24-24c75.1 0 136 60.9 136 136c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-48.6-39.4-88-88-88c-13.3 0-24-10.7-24-24zM117.5 1.4c19.4-5.3 39.7 4.6 47.4 23.2l40 96c6.8 16.3 2.1 35.2-11.6 46.3L144 207.3c33.3 70.4 90.3 127.4 160.7 160.7L345 318.7c11.2-13.7 30-18.4 46.3-11.6l96 40c18.6 7.7 28.5 28 23.2 47.4l-24 88C481.8 499.9 466 512 448 512C200.6 512 0 311.4 0 64C0 46 12.1 30.2 29.5 25.4l88-24z"
                ></path>
              </svg>
              <div>电话联系</div>
            </div>
          </Link>
        </li>
        <li>
          <Link to="#">
            <div className="content">
              <svg
                className="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="#fff"
                  d="M597.333333 746.666667h-34.261333l-18.986667 28.501333L512 823.296l-32.085333-48.128-18.986667-28.501333H170.666667a21.333333 21.333333 0 0 1-21.333334-21.333334V170.666667a21.333333 21.333333 0 0 1 21.333334-21.333334h682.666666a21.333333 21.333333 0 0 1 21.333334 21.333334v554.666666a21.333333 21.333333 0 0 1-21.333334 21.333334h-256zM512 938.666667l38.442667-57.685334L597.333333 810.666667h256a85.333333 85.333333 0 0 0 85.333334-85.333334V170.666667a85.333333 85.333333 0 0 0-85.333334-85.333334H170.666667a85.333333 85.333333 0 0 0-85.333334 85.333334v554.666666a85.333333 85.333333 0 0 0 85.333334 85.333334h256l46.890666 70.314666L512 938.666667zM341.333333 448a64 64 0 1 1-128 0 64 64 0 0 1 128 0z m170.666667 64a64 64 0 1 0 0-128 64 64 0 0 0 0 128z m298.666667-64a64 64 0 1 1-128 0 64 64 0 0 1 128 0z"
                ></path>
              </svg>
              <div>在线咨询</div>
            </div>
          </Link>
        </li>
        <li>
          <Link to="/contact/inquiry">
            <div className="content">
              <svg
                className="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="#fff"
                  d="M832.605091 101.469091H377.949091c-90.112 0-161.792 63.488-161.792 141.312v69.632c6.144-2.048 12.288-2.048 18.432-2.048 61.44-4.096 131.072-6.144 192.512 14.336 100.352-4.096 198.656 2.048 296.96 28.672 149.504 38.912 129.024 180.224 129.024 313.344 79.872-8.192 143.36-67.584 143.36-139.264V242.781091c-2.048-77.824-73.728-141.312-163.84-141.312z"
                  className="__web-inspector-hide-shortcut__"
                ></path>
                <path
                  fill="#fff"
                  d="M713.821091 238.685091h-491.52c-96.256 0-176.128 79.872-176.128 176.128v350.208c0 96.256 79.872 176.128 176.128 176.128h491.52c96.256 0 176.128-79.872 176.128-176.128V414.813091c0-96.256-79.872-176.128-176.128-176.128z m-333.824 161.792h34.816c18.432 0 34.816 16.384 34.816 34.816 0 18.432-16.384 34.816-34.816 34.816h-34.816c-18.432 0-34.816-16.384-34.816-34.816 0-20.48 16.384-34.816 34.816-34.816z m-157.696 0h34.816c18.432 0 34.816 16.384 34.816 34.816 0 18.432-16.384 34.816-34.816 34.816h-34.816c-18.432 0-34.816-16.384-34.816-34.816 0-20.48 16.384-34.816 34.816-34.816z m491.52 401.408h-491.52c-18.432 0-34.816-16.384-34.816-34.816 0-18.432 16.384-34.816 34.816-34.816h491.52c18.432 0 34.816 16.384 34.816 34.816 0 18.432-14.336 34.816-34.816 34.816z m0-176.128h-491.52c-18.432 0-34.816-16.384-34.816-34.816 0-18.432 16.384-34.816 34.816-34.816h491.52c18.432 0 34.816 16.384 34.816 34.816 0 18.432-14.336 34.816-34.816 34.816z"
                ></path>
              </svg>
              <div>咨询留言</div>
            </div>
          </Link>
        </li>
        {showBackToTop && (
          <li>
            <Link
              to="#"
              target="_self"
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
                setShowBackToTop(false);
              }}
            >
              <div className="content">
                <svg
                  className="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="#fff"
                    d="M969.1136 95.232H54.8864a26.9312 26.9312 0 0 1 0-54.272h914.2272a26.9312 26.9312 0 1 1 0 53.76zM727.04 983.04H296.96a26.8288 26.8288 0 0 1-26.9312-26.9312V581.5296H135.5776a26.9312 26.9312 0 0 1-18.432-46.4896L493.568 181.9648a26.8288 26.8288 0 0 1 36.7616 0l376.5248 352.9728a26.9312 26.9312 0 0 1-18.432 46.4896H753.9712v374.272A26.8288 26.8288 0 0 1 727.04 983.04z m-403.2512-54.272h376.4224V554.5984A26.8288 26.8288 0 0 1 727.04 527.6672h93.3888L512 238.4896 203.5712 527.6672H296.96a26.8288 26.8288 0 0 1 26.9312 26.9312z"
                  ></path>
                </svg>
                <div>返回顶部</div>
              </div>
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
};

export default SideBar;
