const Inquiry = () => {
  return (
    <>
      <h1>感谢您对中晟的关注</h1>
      <div>
        <input type="text" placeholder="请填写称呼" />
        <input type="text" placeholder="请填写手机号码" />
      </div>
      <textarea
        name="msg"
        id="msg"
        placeholder="描述您的需求"
        spellcheck="false"
      ></textarea>

      <div>
        <button>提交留言</button>
      </div>
    </>
  );
};

export default Inquiry;
