import { Link } from "react-router-dom";

const SVC = () => {
  return (
    <div className="section-svc">
      <div className="title">
        <h3>OUR SERVICE</h3>
        <h1>我们的服务</h1>
      </div>

      <p>
        ZSTC | 中晟目前主要的业务是体系认证,
        未来业务呈多元化发展态势，提供全方位的、品质有保障的一站式服务。
      </p>

      <div className="services">
        <div className="svc">
          <Link to="/services/qms">
            <img
              src="/assets/images/svc-quality-control-manufacturing.jpg"
              alt=""
            />
            <div className="svc__info">
              <span className="title-en">ISO 9001</span>
              <span className="title-cn">质量管理体系认证</span>
            </div>
          </Link>
        </div>
        <div className="svc">
          <Link to="/services/ems">
            <img
              src="/assets/images/svc-ecology-concept-human-hands-holding-big.jpg"
              alt=""
            />
            <div className="svc__info">
              <span className="title-en">ISO 14001</span>
              <span className="title-cn">环境管理体系认证</span>
            </div>
          </Link>
        </div>
        <div className="svc">
          <Link to="/services/ohsms">
            <img
              src="/assets/images/svc-standard-construction-safety-site.jpg"
              alt=""
            />
            <div className="svc__info">
              <span className="title-en">ISO 45001</span>
              <span className="title-cn">职业健康安全管理体系认证</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SVC;
