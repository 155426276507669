const Ohsms = () => {
  return (
    <div className="ohsms">
      <h1>ISO 45001 职业健康安全管理体系认证（OHSMS）</h1>
      <p>
        职业健康安全管理体系（Occupational Health and Safety Management
        System，OHSMS）认证是指组织通过实施符合国际职业健康安全管理体系标准要求的管理体系，并由独立的认证机构对其进行审核认证的过程。目前，国际上最常用的职业健康安全管理体系标准是
        ISO 45001。ISO
        45001是国际标准化组织（ISO）制定的一套职业健康安全管理体系标准，旨在帮助组织管理、控制和改善其职业健康安全绩效，确保员工在工作环境中的健康和安全，减少职业伤害和疾病。
      </p>
      <p>职业健康安全管理体系认证通常包括以下几个阶段： </p>
      <ul>
        <li>
          <b>准备阶段：</b>{" "}
          组织决定实施职业健康安全管理体系，并开始准备相关文件和程序。
        </li>
        <li>
          <b>培训阶段：</b>
          组织培训员工，使其了解和掌握职业健康安全管理体系的要求。
        </li>
        <li>
          <b>实施阶段：</b>
          组织开始正式实施职业健康安全管理体系，并逐步完善和优化体系。
        </li>
        <li>
          <b>内审阶段：</b>
          组织进行内部审核，评估职业健康安全管理体系的运行情况，并提出改进建议。
        </li>
        <li>
          <b>认证审核阶段：</b>
          组织邀请独立的认证机构进行认证审核，以评估职业健康安全管理体系是否符合ISO
          45001的要求。
        </li>
        <li>
          <b>获证阶段： </b>组织通过认证审核，获得ISO
          45001职业健康安全管理体系认证证书。
        </li>
        <li>
          <b>持续改进阶段：</b>
          组织持续改进职业健康安全管理体系，确保其持续有效运行，并定期接受认证机构的监督审核。
        </li>
      </ul>
      <p>
        职业健康安全管理体系认证能够帮助组织提高员工的工作环境和条件，降低工作风险，减少职业伤害和疾病，提高员工的生产力和工作满意度，同时也有助于提升组织的形象和竞争力。
      </p>
    </div>
  );
};

export default Ohsms;
