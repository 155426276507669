const Academic = () => {
  return (
    <div className="academic">
      <h1>学术园地</h1>
      <p>学术园地版块内容上线中, 敬请期待</p>
    </div>
  );
};

export default Academic;
