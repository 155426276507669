import { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";

const Case = () => {
  const [startPosition, setStartPosition] = useState(0);
  const [currentTranslate, setCurrentTranslate] = useState(0);
  const [prevTranslate, setPrevTranslate] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const slides = [
    {
      link: "#",
      imgSrc: "/assets/images/800px-Aerial_view_of_Apple_Park_dllu.jpeg",
      imgAlt: "Apple headquarter",
      title: "苹果公司三体系认证",
      subTitle: "使能“百模千态”，加速千行万业走向智能化",
    },
    {
      link: "#",
      imgSrc: "/assets/images/qingdao-beer.jpeg",
      imgAlt: "QingDao Beer",
      title: "青岛啤酒食品管理体系认证",
      subTitle: "规范食品安全, 守护人民健康",
    },
    {
      link: "#",
      imgSrc: "/assets/images/digital-city.jpeg",
      imgAlt: "China #1 Construction Co.",
      title: "中国一建 50430",
      subTitle: "点亮繁荣、可持续发展的数字之城",
    },
  ];

  const touchStart = (index) => (event) => {
    setCurrentIndex(index);
    setStartPosition(event.touches[0].clientX);
  };

  const touchMove = (event) => {
    const currentPosition = event.touches[0].clientX;
    setCurrentTranslate(prevTranslate + currentPosition - startPosition);
  };

  const touchEnd = () => {
    // const switchLimit = (window.innerWidth * 0.8) / 2;
    const switchLimit = 100;

    const movedBy = currentTranslate - prevTranslate;

    if (movedBy < -switchLimit && currentIndex < slides.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    } else {
      setCurrentTranslate(prevTranslate);
    }

    if (movedBy > switchLimit && currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    } else {
      setCurrentTranslate(prevTranslate);
    }
  };

  const setPositionByIndex = useCallback(() => {
    setCurrentTranslate(currentIndex * -window.innerWidth);
    setPrevTranslate(currentIndex * -window.innerWidth);
  }, [currentIndex]);

  useEffect(() => {
    const handleResize = () => {
      setPositionByIndex();
    };

    window.addEventListener("resize", handleResize);

    setPositionByIndex();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [currentIndex, setPositionByIndex]);

  const handlePaginationClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <section className="section-case">
      <div className="title">
        <h1>
          <span>成功</span>案例
        </h1>
        <h6 className="english">SUCCESSFUL CASE</h6>
      </div>
      <div className="case-wrapper">
        <div
          className="case"
          onTouchStart={(e) => touchStart(currentIndex)(e)}
          onTouchEnd={touchEnd}
          onTouchMove={touchMove}
          style={{ transform: `translateX(${currentTranslate}px)` }}
        >
          {slides.map((slide, index) => (
            <div key={index} className="box">
              <Link to={slide.link}>
                <img src={slide.imgSrc} alt={slide.imgAlt} />
                <div className="description">
                  <h3 className="title">{slide.title}</h3>
                  <p className="subtitle">{slide.subTitle}</p>
                </div>
                <div className="mask"></div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div class="swiper-pagination-bullets">
        {slides.map((_, index) => (
          <span
            key={index}
            className={`swiper-pagination-bullet ${
              currentIndex === index ? "swiper-pagination-bullet-active" : ""
            }`}
            onClick={() => handlePaginationClick(index)}
            aria-label={`Go to slide ${index}`}
          ></span>
        ))}
      </div>
    </section>
  );
};

export default Case;
