import { LazyLoadImage } from "react-lazy-load-image-component";

const Header = ({ heading, paragraph, children, image }) => {
  return (
    <div className="header">
      <div className="header__img">
        <LazyLoadImage src={image} alt={image} />
      </div>
      <h1 className="header__heading">{heading}</h1>
    </div>
  );
};

export default Header;
